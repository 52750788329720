import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  template: `
    <app-login-screen>
      <p class="text-center">{{ 'LOGOUT.SIGNING_OUT' | translate }}...</p>
    </app-login-screen>
  `,
})
export class LogoutComponent implements OnInit {
  ngOnInit(): void {}
}
