import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <div
      class="flex flex-col items-center justify-center h-full space-y-2 text-center"
    >
      <div class="text-primary-500">404</div>
      <h1 class="text-4xl font-bold text-gray-900">Page not found.</h1>
      <div class="text-gray-500">
        Sorry, the page you are looking for could not be found.
      </div>
      <button mat-flat-button color="primary" [routerLink]="['/']">
        Go back home
      </button>
    </div>
  `,
})
export class NotFoundComponent implements OnInit {
  ngOnInit(): void {}
}
