import { Component, Input, OnInit } from '@angular/core';
import { AppRoute } from '../../../../routes';

@Component({
  selector: 'app-sidebar-item-predefined',
  template: `
    @if (
      (route.roles | hasPermission | async) &&
      (route.requiredFeatures | hasFeature | async)
    ) {
      <app-sidebar-item [link]="[route.path]" [icon]="route.icon">
        <ng-container slot="title">
          {{ route.name | translate }}
        </ng-container>
      </app-sidebar-item>
    }
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SidebarItemPredefinedComponent implements OnInit {
  @Input() route!: AppRoute;

  ngOnInit(): void {}
}
