import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, inject } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private injector = inject<Injector>(Injector);

  override handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 403:
          this.toastService.error(
            'You are not authorized to perform this action',
          );
          break;
        case 0:
          this.toastService.error('Unable to reach server');
          break;
        default:
          break;
      }
    }

    super.handleError(error);
  }

  private get toastService() {
    return this.injector.get(HotToastService);
  }
}
