import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError, lastValueFrom, of } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogOptions,
  DialogConfirmationButtonRole,
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { differs } from '../../../shared/form-validators/differs.validator';
import { matches } from '../../../shared/form-validators/matches.validator';
import {
  AuthState,
  ChangeEmail,
  ChangePassword,
  LoadUserDetails,
  RequestAccountDeletion,
  RequestAllUserInformation,
} from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-profile-page',
  template: `
    <app-page
      [loading]="userDetailsLoading$ | async"
      [loaded]="userDetailsLoaded$ | async"
      [error]="userDetailsError$ | async"
      (refresh)="refresh()"
      [footer]="false"
      style="--page-width: 100%"
    >
      <ng-container slot="title">My Account</ng-container>
      <ng-container slot="actions">
        <button mat-flat-button (click)="dialogRef.close()">Close</button>
      </ng-container>

      <div slot="body" class="flex flex-col space-y-4 overflow-y-auto">
        <!-- change email -->
        @if (
          (userDetails$ | async)?.user && (userDetails$ | async);
          as userDetails
        ) {
          <div>
            <h2 class="mb-4">Change my email address</h2>
            <form
              class="flex flex-col space-y-4 md:max-w-lg"
              [formGroup]="emailChangeForm"
              (ngSubmit)="changeEmail()"
            >
              <mat-form-field>
                <mat-label>Current email address</mat-label>
                <input matInput disabled [value]="userDetails.user?.email" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>New email address</mat-label>
                <input
                  matInput
                  placeholder="new.email@example.com"
                  type="email"
                  [formControl]="emailChangeForm.controls['newEmail']"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Confirm new email address</mat-label>
                <input
                  matInput
                  placeholder="new.email@example.com"
                  type="email"
                  [formControl]="emailChangeForm.controls['newEmailRepeat']"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input
                  matInput
                  type="password"
                  [formControl]="emailChangeForm.controls['password']"
                />
              </mat-form-field>
              <app-inline-help>
                For security, your password is required to verify that you are
                making the change.
              </app-inline-help>
              <mat-checkbox
                [formControl]="emailChangeForm.controls['confirmCorrectEmail']"
              >
                <label class="whitespace-normal">
                  I confirm that the new email address above is correct
                </label>
              </mat-checkbox>
              <mat-checkbox
                [formControl]="emailChangeForm.controls['confirmBelongsToMe']"
              >
                <label class="whitespace-normal">
                  I confirm that the new email address belongs to me
                </label>
              </mat-checkbox>
              <mat-checkbox
                [formControl]="emailChangeForm.controls['confirmUnderstand']"
              >
                <label class="whitespace-normal">
                  I understand that I will only be able to use the new email
                  address to sign in
                </label>
              </mat-checkbox>
              <div class="flex justify-end mb-4">
                <button
                  type="submit"
                  mat-flat-button
                  color="primary"
                  [disabled]="emailChangeLoading$ | async"
                >
                  <span>Update email</span>
                  @if (emailChangeLoading$ | async) {
                    <app-loading-spinner></app-loading-spinner>
                  }
                </button>
              </div>
              <app-api-error
                [error]="emailChangeError$ | async"
              ></app-api-error>
              @if (
                emailChangeForm.touched &&
                emailChangeForm.dirty &&
                emailChangeForm.hasError('matches')
              ) {
                <app-error>Email addresses do not match</app-error>
              }
              @if (
                emailChangeForm.touched &&
                emailChangeForm.dirty &&
                (emailChangeForm.controls['confirmCorrectEmail'].hasError(
                  'required'
                ) ||
                  emailChangeForm.controls['confirmBelongsToMe'].hasError(
                    'required'
                  ) ||
                  emailChangeForm.controls['confirmUnderstand'].hasError(
                    'required'
                  ))
              ) {
                <app-error>
                  Please ensure that you have read and checked all of the
                  checkboxes above
                </app-error>
              }
            </form>
            <hr />
          </div>
        }

        <!-- Change password -->
        <div>
          <h2 class="mb-4">Change my password</h2>

          <form
            class="flex flex-col space-y-4 md:max-w-lg"
            [formGroup]="passwordChangeForm"
            (ngSubmit)="changePassword()"
          >
            <mat-form-field>
              <mat-label>Current password</mat-label>
              <input
                matInput
                type="password"
                [formControl]="passwordChangeForm.controls['currentPassword']"
              />
              @if (
                passwordChangeForm.controls['currentPassword'].hasError(
                  'required'
                )
              ) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>

            <hr />

            <mat-form-field>
              <mat-label>New password</mat-label>
              <input
                matInput
                type="password"
                [formControl]="passwordChangeForm.controls['newPassword']"
              />
              @if (
                passwordChangeForm.controls['newPassword'].hasError('required')
              ) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <app-password-strength
              [zxcvbn]="
                passwordChangeForm.controls['newPassword'].getError('zxcvbn')
              "
              [password]="passwordChangeForm.controls['newPassword'].value"
            ></app-password-strength>

            <mat-form-field>
              <mat-label>Repeat New password</mat-label>
              <input
                matInput
                type="password"
                [formControl]="passwordChangeForm.controls['newPasswordRepeat']"
              />
              @if (
                passwordChangeForm.controls['newPasswordRepeat'].hasError(
                  'required'
                )
              ) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>

            <mat-checkbox
              [formControl]="passwordChangeForm.controls['confirmPassword']"
            >
              I confirm that I wish to change my password
            </mat-checkbox>
            <mat-checkbox
              [formControl]="passwordChangeForm.controls['confirmUnderstand']"
            >
              I understand that I will only be able to use the new password to
              sign in
            </mat-checkbox>

            <div>
              This will redirect you to the login page after successfully
              changing your password
            </div>

            <div class="flex justify-end mb-4">
              <button
                type="submit"
                mat-flat-button
                color="primary"
                [disabled]="emailChangeLoading$ | async"
              >
                <span>Update password</span>
                @if (emailChangeLoading$ | async) {
                  <app-loading-spinner></app-loading-spinner>
                }
              </button>
            </div>

            <app-api-error
              [error]="passwordChangeError$ | async"
            ></app-api-error>

            @if (
              passwordChangeForm.touched &&
              passwordChangeForm.dirty &&
              passwordChangeForm.hasError('matches')
            ) {
              <app-error>New Passwords do not match</app-error>
            }

            @if (
              passwordChangeForm.touched &&
              passwordChangeForm.dirty &&
              passwordChangeForm.hasError('differs')
            ) {
              <app-error>
                New Password cannot be the same as the old password
              </app-error>
            }

            @if (
              passwordChangeForm.touched &&
              passwordChangeForm.dirty &&
              (passwordChangeForm.controls['confirmPassword'].hasError(
                'required'
              ) ||
                passwordChangeForm.controls['confirmUnderstand'].hasError(
                  'required'
                ))
            ) {
              <app-error>
                Please ensure that you have read and checked all of the
                checkboxes above
              </app-error>
            }
          </form>

          <hr />
        </div>

        <!-- Request Personal Data -->
        <div class="prose">
          <h2 class="mb-4">Request my data</h2>
          <p>
            You can request a payload containing all metadata related to your
            user by clicking on the button below. This will include data related
            to your employee profile, Compensation, Benefits, Paid Time Off and
            other types of data related to your user.
          </p>

          <button
            mat-flat-button
            color="primary"
            (click)="requestAllMyData()"
            [disabled]="fetchingUserInformation$ | async"
          >
            <span>Request my data</span>
            @if (fetchingUserInformation$ | async) {
              <app-loading-spinner></app-loading-spinner>
            }
          </button>

          <p>
            All your documents should be visible to you from this website, and
            are included in the data request. If you would like to request any
            additional information relating to documents (payslips, tax forms,
            etc.) please contact your company administrator using the help form.
          </p>

          <p>
            If you would like to delete any information, please contact your
            company administrator using the help form.
          </p>
        </div>

        <hr />

        @if ((userDetails$ | async)?.user) {
          <section class="prose">
            <h2>Delete my user account</h2>
            <p>
              You can delete your user account by clicking on the button below.
            </p>
            <p>
              You will be logged out and no longer be able to sign back in to
              the system.
            </p>
            <p>
              Your data will
              <b>not</b>
              be automatically deleted from the system. Your company
              administrators will be notified that you wish for your data to be
              removed.
            </p>
            <form
              (ngSubmit)="requestAccountDeletion()"
              [formGroup]="deleteAccountForm"
            >
              <mat-form-field>
                <mat-label>Confirm your password</mat-label>
                <input
                  matInput
                  type="password"
                  [formControl]="deleteAccountForm.controls['password']"
                />
              </mat-form-field>
              <div class="flex justify-end">
                <button mat-flat-button color="primary" type="submit">
                  Request account deletion
                </button>
              </div>
            </form>
            <hr />
          </section>
        }

        @if (userDetails$ | async; as userDetails) {
          <h2>Account identifiers</h2>
          <pre class="whitespace-normal">{{ userDetails.companyId }}</pre>
          <pre class="whitespace-normal">{{ userDetails.id }}</pre>
          <pre class="whitespace-normal">{{ userDetails.user?.id }}</pre>
          <pre class="whitespace-normal">{{ userDetails.user?.email }}</pre>
        }
      </div>
    </app-page>
  `,
  styles: [],
})
export class ProfilePageComponent implements OnInit {
  dialogRef: MatDialogRef<ProfilePageComponent> = inject(MatDialogRef);
  private store = inject(Store);
  private fb = inject(FormBuilder);
  private toast = inject(HotToastService);
  private matDialog = inject(MatDialog);

  userDetails$ = this.store.select(AuthState.userDetails);
  userDetailsLoading$ = this.store.select(AuthState.loadingUserDetails);
  userDetailsLoaded$ = this.store.select(AuthState.loadedUserDetails);
  userDetailsError$ = this.store.select(AuthState.userDetailsError);
  emailChangeLoading$ = new BehaviorSubject(false);
  emailChangeError$ = new BehaviorSubject(null);
  passwordChangeLoading$ = new BehaviorSubject(false);
  passwordChangeError$ = new BehaviorSubject(null);

  fetchingUserInformation$ = new BehaviorSubject(false);
  userInformationRequestError$ = new BehaviorSubject(null);

  emailChangeForm = this.fb.group(
    {
      newEmail: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      newEmailRepeat: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl<string>('', [Validators.required]),
      confirmCorrectEmail: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
      confirmBelongsToMe: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
      confirmUnderstand: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
    },
    {
      validators: [matches('newEmail', 'newEmailRepeat')],
    },
  );

  passwordChangeForm = this.fb.group(
    {
      currentPassword: new FormControl<string>('', [Validators.required]),
      newPassword: new FormControl<string>('', [Validators.required]),
      newPasswordRepeat: new FormControl<string>('', [Validators.required]),
      confirmPassword: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
      confirmUnderstand: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
    },
    {
      validators: [
        matches('newPassword', 'newPasswordRepeat'),
        differs('currentPassword', 'newPassword'),
      ],
    },
  );

  deleteAccountForm = this.fb.group({
    password: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {}

  refresh() {
    this.store.dispatch(new LoadUserDetails());
  }

  changeEmail() {
    this.emailChangeError$.next(null);

    this.emailChangeForm.markAsDirty();
    this.emailChangeForm.markAllAsTouched();

    if (!this.emailChangeForm.valid) {
      return;
    }

    this.emailChangeLoading$.next(true);

    this.store
      .dispatch(new ChangeEmail(this.emailChangeForm.value as any))
      .pipe(
        this.toast.observe({
          loading: 'Sending confirmation email...',
          success: 'Confirmation email delivered',
          error: 'An error occurred',
        }),
        untilDestroyed(this),
        catchError((err) => {
          this.emailChangeError$.next(err);
          this.emailChangeLoading$.next(false);
          return of();
        }),
      )
      .subscribe(() => {
        this.emailChangeLoading$.next(false);

        const dialogOptions: ConfirmationDialogOptions = {
          title: 'Email change confirmation',
          body: 'An email has been sent to your new email address. Please follow the instructions in the email to confirm the change.',
          buttons: [
            {
              text: 'Close',
              secondary: true,
              role: DialogConfirmationButtonRole.Accept,
            },
          ],
        };
        this.matDialog.open(ConfirmationDialogComponent, {
          data: dialogOptions,
        });
      });
  }

  changePassword() {
    this.passwordChangeError$.next(null);

    this.passwordChangeForm.markAsDirty();
    this.passwordChangeForm.markAllAsTouched();

    if (!this.passwordChangeForm.valid) {
      return;
    }

    this.passwordChangeLoading$.next(true);

    this.store
      .dispatch(new ChangePassword(this.passwordChangeForm.value as any))
      .pipe(
        this.toast.observe({
          loading: 'Awaiting Password Update...',
          success: 'Password Updated',
          error: 'An error occurred',
        }),
        untilDestroyed(this),
        catchError((err) => {
          this.passwordChangeError$.next(err);
          this.passwordChangeLoading$.next(false);
          return of();
        }),
      )
      .subscribe(() => {
        this.passwordChangeLoading$.next(false);
        this.dialogRef.close();
      });
  }

  requestAllMyData() {
    this.fetchingUserInformation$.next(true);
    this.store
      .dispatch(new RequestAllUserInformation())
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.userInformationRequestError$.next(err);
          this.fetchingUserInformation$.next(false);
          return of();
        }),
      )
      .subscribe((data) => {
        this.fetchingUserInformation$.next(false);
      });
  }

  async requestAccountDeletion() {
    this.deleteAccountForm.markAllAsTouched();
    this.deleteAccountForm.markAsDirty();
    if (!this.deleteAccountForm.valid) return;

    const dialog = await this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete account',
        subtitle:
          'Are you sure you want to delete your account? This cannot be undone.',
        body: 'Company administrators will be notified that you wish for your data to be removed.',
        confirmationValue: 'DELETE ACCOUNT',
        buttons: [
          {
            text: 'Cancel',
            secondary: true,
            role: DialogConfirmationButtonRole.Cancel,
          },
          {
            text: 'Delete account',
            primary: true,
            role: DialogConfirmationButtonRole.Accept,
          },
        ],
      } as ConfirmationDialogOptions,
    });

    const res = await lastValueFrom(dialog.afterClosed());
    if (res !== DialogConfirmationButtonRole.Accept) return;

    this.store
      .dispatch(
        new RequestAccountDeletion({
          password: this.deleteAccountForm.value.password as string,
        }),
      )
      .pipe(
        untilDestroyed(this),
        this.toast.observe({
          loading: 'Requesting account deletion...',
          success: 'Account deleted',
          error: 'Error requesting account deletion',
        }),
      )
      .subscribe();
  }
}
