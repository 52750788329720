import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-item',
  template: `
    <li>
      <a
        class="relative flex items-center p-2 px-3 space-x-2 overflow-x-hidden text-base font-normal text-white no-underline rounded-lg cursor-pointer hover:bg-gray-800 active-item:bg-primary-600 group/navitem pl-[calc(theme('spacing.3')+var(--sidebar-item-padding-factor,0)*theme('spacing.3'))] sm:pl-3 xl:pl-[calc(theme('spacing.3')+var(--sidebar-item-padding-factor,0)*theme('spacing.3'))] sm:group-hover:pl-[calc(theme('spacing.3')+var(--sidebar-item-padding-factor,0)*theme('spacing.3'))] transition-[padding-left] duration-300"
        matRipple
        [routerLink]="link"
        [routerLinkActive]="noActive ? [] : ['active']"
      >
        <mat-icon
          aria-hidden="true"
          class="z-10 text-xl text-gray-200 child active-item:text-primary-200 shrink-0 leading-[theme('spacing.6')] text-center"
        >
          {{ icon }}
        </mat-icon>
        <div
          class="z-10 text-sm text-gray-100 transition-opacity opacity-0 whitespace-nowrap active-item:text-white active-item:font-semibold group-hover:opacity-100 nav-open:opacity-100 xl:opacity-100 group-hover/navitem:underline"
        >
          <ng-content select="[slot=title]"></ng-content>
        </div>
        <div
          class="absolute top-0 bottom-0 z-0 flex items-center content-center transition-opacity duration-300 sm:opacity-0 right-2 group-hover:opacity-100 xl:opacity-100"
        >
          <ng-content select="[slot=right]"></ng-content>
        </div>
      </a>
    </li>
  `,
})
export class SidebarItemComponent implements OnInit {
  @Input() link: string | string[] | null = null;
  @Input() icon = 'home_outline';
  @Input() noActive = false; // prevent the link from appearing active

  ngOnInit(): void {}
}
