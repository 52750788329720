import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { AuthState, LogOut, RefreshAuthTokens } from '../../auth/auth.state';

/**
 * Intercepts all http requests and adds the access token to the request header if it is available.
 * Also handles the 401 error by refreshing the access token and retrying the request.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private store = inject(Store);

  isRefreshingToken = false;
  tokenSubject = new BehaviorSubject<string | null>(null);

  isLoadingEmployeeDetails = false;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isAuthenticated = this.store.selectSnapshot(
      AuthState.isAuthenticated,
    );

    // when not authenticated (no refresh token/refresh token has expired) do nothing
    if (!isAuthenticated) return next.handle(req);

    // first try with the store's access token
    const accessToken = this.store.selectSnapshot(AuthState.accessTokenRaw);

    return next.handle(this.addToken(req, accessToken)).pipe(
      catchError((error) => {
        // handle any 401 errors by refreshing the access token
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      }),
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // ensure that the token can only be refreshed by one request at a time
    // and cache the token for subsequent requests that also got a 401
    if (this.isRefreshingToken) {
      return this.tokenSubject.pipe(
        filter((token) => !!token),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(req, token));
        }),
      );
    }

    // refresh the token
    this.isRefreshingToken = true;
    this.tokenSubject.next(null);

    return this.store.dispatch(new RefreshAuthTokens()).pipe(
      switchMap(() => {
        const newToken = this.store.selectSnapshot(AuthState.accessTokenRaw);
        if (newToken) {
          this.tokenSubject.next(newToken);
          return next.handle(this.addToken(req, newToken));
        }

        // the token wasn't found, something went wrong with the refresh
        return this.logOut();
      }),
      finalize(() => {
        this.isRefreshingToken = false;
      }),
    );
  }

  private addToken(req: HttpRequest<any>, token: string | null) {
    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private logOut() {
    // log the user out if we didn't get a token
    this.store.dispatch(new LogOut());
    return throwError(() => 'Could not refresh token');
  }
}
