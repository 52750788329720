import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subject, catchError, map, of, takeUntil } from 'rxjs';
import { otpMask } from '../../../shared/masks/otp.mask';
import { AuthState, GenerateOtp, LogIn } from '../../auth.state';

@Component({
  selector: 'app-login',
  template: `
    <app-login-screen>
      @if (
        (hasSamlEnabled$ | async) &&
          !isUsingAlternativeSignInMethod &&
          (details$ | async);
        as details
      ) {
        <app-login-intro>
          <ng-container slot="title">
            {{ 'LOGIN.WELCOME' | translate }}
          </ng-container>
          <ng-container slot="subtitle">
            {{ 'LOGIN.WELCOME_SUBTITLE' | translate }}
          </ng-container>
        </app-login-intro>

        <a href="/api/v1/auth/saml/login" class="block mt-4">
          @if (details.samlLogo) {
            <div class="flex justify-center h-10">
              <img src="{{ makeSamlLogo(details.samlLogo) }}" />
            </div>
          } @else {
            <div
              matRipple
              class="px-4 py-2 text-sm text-center text-white no-underline border rounded bg-primary-500"
            >
              @if (details.samlName) {
                {{
                  'LOGIN.SIGN_IN_WITH'
                    | translate: { provider: details.samlName }
                }}
              } @else {
                {{ 'LOGIN.SINGLE_SIGN_ON' | translate }}
              }
            </div>
          }
        </a>

        <div class="text-center mt-8 text-sm">
          <button
            (click)="isUsingAlternativeSignInMethod = true"
            class="text-primary-500 underline cursor-pointer"
          >
            {{ 'LOGIN.SIGN_IN_WITH_EMAIL_PASSWORD' | translate }}
          </button>
        </div>
      }

      @if (isUsingAlternativeSignInMethod || !(hasSamlEnabled$ | async)) {
        <!-- generate OTP form (email and password) -->
        @if (!generatedOtp) {
          <form
            [formGroup]="loginForm"
            (ngSubmit)="generateOtp()"
            class="space-y-4"
          >
            <app-login-intro>
              <ng-container slot="title">
                {{ 'LOGIN.WELCOME' | translate }}
              </ng-container>
              <ng-container slot="subtitle">
                {{ 'LOGIN.WELCOME_SUBTITLE' | translate }}
              </ng-container>
            </app-login-intro>
            <mat-form-field>
              <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
              <input
                id="email"
                type="text"
                matInput
                [formControl]="loginForm.controls['email']"
                [placeholder]="'LOGIN.EMAIL_PLACEHOLDER' | translate"
                spellcheck="false"
              />
              @if (loginForm.controls['email'].hasError('required')) {
                <mat-error>
                  {{ 'LOGIN.EMAIL_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
              <input
                id="password"
                type="password"
                matInput
                [formControl]="loginForm.controls['password']"
              />
              @if (loginForm.controls['password'].hasError('required')) {
                <mat-error>
                  {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <app-api-error [error]="authError$ | async"></app-api-error>
            <div class="flex items-center justify-between">
              <a mat-button [routerLink]="'/auth/forgot-password'">
                {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
              </a>
              <button
                id="login-button"
                mat-flat-button
                color="primary"
                type="submit"
                [disabled]="generatingOtp$ | async"
              >
                <span>{{ 'LOGIN.SIGN_IN' | translate }}</span>
                @if (generatingOtp$ | async) {
                  <app-loading-spinner></app-loading-spinner>
                }
              </button>
            </div>
          </form>
        }

        <!-- enter OTP form (OTP code) -->
        @if (generatedOtp) {
          <form [formGroup]="otpForm" (ngSubmit)="logIn()" class="space-y-4">
            <app-login-intro>
              <ng-container slot="title">
                {{ 'LOGIN.OTP_TITLE' | translate }}
              </ng-container>
              <ng-container slot="subtitle">
                {{ 'LOGIN.OTP_INSTRUCTIONS' | translate }}
              </ng-container>
            </app-login-intro>
            <mat-form-field>
              <mat-label>{{ 'LOGIN.OTP' | translate }}</mat-label>
              <input
                id="otp-field"
                type="text"
                matInput
                [formControl]="otpForm.controls['otpCode']"
                [mask]="otpMask"
                (ngModelChange)="transformOtp()"
                placeholder="123-456"
              />
              @if (otpForm.controls['otpCode'].hasError('required')) {
                <mat-error>
                  {{ 'LOGIN.OTP_REQUIRED' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <app-api-error [error]="authError$ | async"></app-api-error>
            <div class="flex items-center justify-between">
              <button
                id="otp-button"
                mat-button
                type="button"
                (click)="generateOtp()"
                [disabled]="generatingOtp$ | async"
              >
                <span>{{ 'LOGIN.RESEND_OTP' | translate }}</span>
                @if (generatingOtp$ | async) {
                  <app-loading-spinner></app-loading-spinner>
                }
              </button>
              <button
                mat-flat-button
                color="primary"
                type="submit"
                [disabled]="loggingIn$ | async"
              >
                <span>{{ 'LOGIN.SIGN_IN' | translate }}</span>
                @if (loggingIn$ | async) {
                  <app-loading-spinner></app-loading-spinner>
                }
              </button>
            </div>
          </form>
        }

        @if (hasSamlEnabled$ | async) {
          <div class="text-center mt-8 text-sm">
            <button
              (click)="isUsingAlternativeSignInMethod = false"
              class="text-primary-500 underline cursor-pointer"
            >
              {{ 'LOGIN.SIGN_IN_WITH_SSO' | translate }}
            </button>
          </div>
        }
      }
    </app-login-screen>
  `,
})
export class LoginComponent {
  private store = inject(Store);
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  generatingOtp$ = this.store.select(AuthState.generatingOtp);

  loggingIn$ = this.store.select(AuthState.loggingIn);

  details$ = this.store.select(AuthState.publicCompanyDetails);
  hasSamlEnabled$ = this.details$.pipe(
    map((details) => details && details.entrypoint),
  );

  authError$ = new BehaviorSubject(null);

  generatedOtp = false;

  otpMask = otpMask;

  isUsingAlternativeSignInMethod = false;

  loginForm = this.fb.nonNullable.group({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  otpForm = this.fb.nonNullable.group({
    otpCode: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
      ],
    }),
  });

  onDestroy$ = new Subject<void>();
  onDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Generate OTP code from email and password
   * @returns
   */
  generateOtp() {
    this.loginForm.markAllAsTouched();
    this.loginForm.markAsDirty();
    if (!this.loginForm.valid) {
      return;
    }

    this.authError$.next(null);

    this.store
      .dispatch(
        new GenerateOtp({
          email: this.loginForm.controls['email'].value,
          password: this.loginForm.controls['password'].value,
        }),
      )
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err) => {
          this.authError$.next(err);
          return of();
        }),
      )
      .subscribe({
        next: () => {
          this.generatedOtp = true;
          this.authError$.next(null);
        },
      });
  }

  /**
   * Submit the user's OTP code and verify
   * @returns
   */
  logIn() {
    this.otpForm.markAllAsTouched();
    this.otpForm.markAsDirty();
    if (!this.otpForm.valid) {
      return;
    }

    this.authError$.next(null);

    this.store
      .dispatch(
        new LogIn({
          email: this.loginForm.controls['email'].value,
          password: this.loginForm.controls['password'].value,
          otpCode: this.otpForm.controls['otpCode'].value,
        }),
      )
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err) => {
          this.authError$.next(err);
          return of();
        }),
      )
      .subscribe({
        next: () => {
          this.router.navigate(['auth/tenant-selection'], {
            queryParamsHandling: 'preserve',
          });
        },
      });
  }

  lastOtpValue = '';
  transformOtp() {
    const control = this.otpForm.controls['otpCode'];
    if (control.value === this.lastOtpValue) {
      return;
    }

    this.lastOtpValue = control.value;
    control.setValue(control.value.toLocaleUpperCase());
  }

  makeSamlLogo(svg: string) {
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  }
}
