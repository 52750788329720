import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs';
import { SidebarItemPredefinedComponent } from '../sidebar-item-predefined/sidebar-item-predefined.component';

@UntilDestroy()
@Component({
  selector: 'app-sidebar-submenu',
  template: `
    <div
      class="dropdown-header"
      [class.sub-item-selected]="expanded && !(headerIsSelected$ | async)"
    >
      <ng-content select="[slot=dropdown-header]"></ng-content>
    </div>

    <div
      class="overflow-y-hidden bg-gray-900 rounded-lg"
      style="--sidebar-item-padding-factor: 1"
      [style.height]="expanded ? 'auto' : '0'"
    >
      <ng-content select="[slot=dropdown-items]"></ng-content>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .dropdown-header.sub-item-selected a {
        @apply bg-gray-700;

        .mat-icon {
          @apply text-gray-300;
        }
      }
    `,
  ],
})
export class SidebarSubmenuComponent implements OnInit, AfterContentInit {
  router = inject(Router);
  routeSnapshot = this.router.routerState.snapshot;

  @Input() exactMatchRoute = false;

  @ContentChildren(SidebarItemPredefinedComponent, {})
  childItems!: QueryList<SidebarItemPredefinedComponent>;

  afterContentInit$ = new BehaviorSubject(null);

  headerIsSelected$ = combineLatest([
    this.router.events,
    this.afterContentInit$,
  ]).pipe(
    filter(([event]) => event instanceof NavigationEnd),
    map(([event]) => {
      if (!(event instanceof NavigationEnd)) {
        return false;
      }
      return event.url === this.childItems.first.route.path;
    }),
  );
  expanded = false;

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.onRouteEvent(this.routeSnapshot);
    this.afterContentInit$.next(null);

    // check if the current route is a child of the dropdown
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((val) => {
        this.onRouteEvent(val);
      });
  }

  private onRouteEvent(evt: any) {
    const currentRoute = evt.url;

    this.expanded = this.childItems.some((item) => {
      return currentRoute.startsWith(item.route.path);
    });
  }
}
