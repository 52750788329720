import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
  inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { SidebarItemPredefinedComponent } from '../sidebar-item-predefined/sidebar-item-predefined.component';

@UntilDestroy()
@Component({
  selector: 'app-sidebar-dropdown',
  template: `
    <ng-content select="[slot=dropdown-header]"></ng-content>

    <app-sidebar-item (click)="toggleExpanded()" [icon]="icon">
      <ng-container slot="title">
        {{ heading }}
      </ng-container>
      <ng-container slot="right">
        <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
      </ng-container>
    </app-sidebar-item>

    <div
      class="overflow-y-hidden bg-gray-900 rounded-lg"
      style="--sidebar-item-padding-factor: 1"
      [style.height]="expanded ? 'auto' : '0'"
    >
      <ng-content select="[slot=dropdown-items]"></ng-content>
    </div>
  `,
})
export class SidebarDropdownComponent implements AfterContentInit {
  router = inject(Router);
  routeSnapshot = this.router.routerState.snapshot;

  @ContentChildren(SidebarItemPredefinedComponent, {})
  childItems!: QueryList<SidebarItemPredefinedComponent>;

  @Input() heading = '';
  @Input() icon = '';
  expanded = false;

  ngAfterContentInit(): void {
    this.onRouteEvent(this.routeSnapshot);
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event) => this.onRouteEvent(event));
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  private onRouteEvent(evt: any) {
    if (this.expanded) return; // don't ever collapse - only expand

    const currentRoute = evt.url;

    this.expanded = this.childItems.some((item) => {
      return currentRoute.startsWith(item.route.path);
    });
  }
}
